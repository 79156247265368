import { INextPool } from '@chowlett/watgolf-ddb-onetable-schema'
import { formatTournamentDates } from '../utils/Utils'

export interface INextPoolProps {
  poolPlus: INextPool | undefined
}

export const NextPool = ({ poolPlus }: INextPoolProps) => {
  if (!poolPlus) {
    return (
      <div>
        <div className='font-bold text-xl'>Next Pool</div>
        <div>No more pools this season</div>
      </div>
    )
  }

  return (
    <div>
      <div className='font-bold text-xl'>Next Pool</div>
      <div>
        {poolPlus.pool.name} - {formatTournamentDates(poolPlus.tournament)}
      </div>
    </div>
  )
}
