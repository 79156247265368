import {
  Link,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom'
import { PoolView } from './pools/components/PoolView'
import { Pools } from './pools/Pools'
import { Tournaments } from './tournaments/Tournaments'
import { Users } from './users/Users'

export const AdminHome = () => {
  const { pathname } = useLocation()

  console.log(
    `AdminHome pathname=${pathname}`
  )

  return (
    <>
      <div className='flex'>
        <Link className='w-28 text-center' to='pools'>
          Pools
        </Link>
        <Link className='w-28 text-center' to='tnmts'>
          Tournaments
        </Link>
        <Link className='w-28 text-center' to='users'>
          Users
        </Link>
      </div>
      <Routes>
        <Route path='' element={<Navigate to='pools'/>} />
        <Route path='pools/*' element={<Pools />} />
        <Route path='tnmts/*' element={<Tournaments />} />
        <Route path='users/*' element={<Users />} />
        <Route path='pool/:id' element={<PoolView />} />
      </Routes>
    </>
  )
}
