export const WatgolfLogo = () => {
  return (
    <span
      className='inline-block text-lg'
      style={{ fontFamily: 'Bouncy', height: '38px', paddingTop: '.4rem' }}
    >
      Watgolf
    </span>
  )
}
