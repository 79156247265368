import { Field, FormikErrors, FormikTouched } from 'formik'
import {
  getErrorProps,
  getFieldProps,
  getLabelProps,
  IFieldDef,
  IFieldDefDefaultable,
} from './FieldDefs'

export const renderFieldDef = (
  fieldDef: IFieldDef,
  defaults: IFieldDefDefaultable,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>
) => {
  const labelProps = getLabelProps(fieldDef, defaults)
  const fieldProps = getFieldProps(fieldDef, defaults)
  const errorProps = getErrorProps(fieldDef, defaults)

  return (
    <div key={fieldDef.name}>
      <div className='flex'>
        <label htmlFor={fieldDef.name} {...labelProps}>
          {fieldDef.label}
        </label>
        <Field name={fieldDef.name} {...fieldProps} />
      </div>
      {touched.name && errors.name && <div {...errorProps}>{errors.name}</div>}
    </div>
  )
}
