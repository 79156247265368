import {
  IDashboardData,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { useLocation } from 'react-router-dom'
import { CurrentPool } from './components/CurrentPool'
import { NextPool } from './components/NextPool'
import { PreviousPool } from './components/PreviousPool'

export interface IDashboardProps {
  dashboardData: IDashboardData
}

export const Dashboard = ({ dashboardData }: IDashboardProps) => {
  const { pathname } = useLocation()

  console.log(`Dashboard render path=${pathname}`)

  return (
    <div className='ml-4'>
      <div>
        <CurrentPool actionState={dashboardData.state.actionState} poolPlus={dashboardData.current} />
      </div>
      <div className='mt-3'>
        <PreviousPool poolPlus={dashboardData.previous} />
      </div>
      <div className='mt-3'>
        <NextPool poolPlus={dashboardData.next} />
      </div>
    </div>
  )
}
