import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import {
  IDashboardData,
  ITournamentField,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { API } from 'aws-amplify'
import { useQuery } from 'react-query'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Dashboard } from './dashboard/Dashboard'

import { IPickFormProps, PickForm } from './pick'

dayjs.extend(utc)
dayjs.extend(timezone)
interface IDashboardApiResponse {
  data?: IDashboardData
  error?: any
}

export const UserHome = () => {
  const { pathname } = useLocation()

  console.log(`UserHome render pathname=${pathname}`)

  const { isLoading, isError, data, error, refetch } = useQuery<IDashboardData>(
    'dashboard-data',
    async () => {
      const response = (await API.get(
        'wgapi',
        '/pool/dashboard',
        {}
      )) as IDashboardApiResponse

      if (response.data) {
        return response.data
      }

      throw new Error(
        `Error while fetching dashboard data: ${
          response.error ?? 'No details available'
        }`
      )
    },
    {
      refetchInterval: getRefreshInterval(),
    }
  )

  if (isLoading) {
    return <div>Loading ...</div>
  }

  if (isError) {
    return <div>Sorry, we encountered an error: {error}</div>
  }

  const dashboardData = data as IDashboardData

  if (!dashboardData) {
    console.log('No dashboard data')

    if (!!data) {
      console.log(`useQuery returned ${JSON.stringify(data, null, 2)}`)
    } else {
      console.log('useQuery returned no data')
    }

    return <div>No dashboard data available</div>
  }

  logDashboardResult(dashboardData)

  const pickSubmitHandler = () => {
    refetch()
  }

  return (
    <div>
      <Routes>
        <Route
          path='pick'
          element={
            dashboardData.current && dashboardData.current.tournament.field ? (
              <PickForm
                {...pickFormPropsPartial(dashboardData)}
                submitHandler={pickSubmitHandler}
              />
            ) : (
              <Navigate to='' />
            )
          }
        >
          {}
        </Route>
        <Route path="*" element={<Dashboard dashboardData={dashboardData} />} />
      </Routes>
    </div>
  )
}

const pickFormPropsPartial = (
  dashboardData: IDashboardData
): Omit<IPickFormProps, 'submitHandler'> => {
  const poolId = dashboardData.current?.pool.id as string
  const players = (dashboardData.current?.tournament.field as ITournamentField)
    .players
  const poolEntry = dashboardData.current?.poolEntry

  return {
    poolId,
    players,
    poolEntry,
  }
}

const getRefreshInterval = (): number | undefined => {
  const now = dayjs().tz('America/Toronto')

  // Mon, Tue, Wed - no refresh
  if (now.day() >= 1 && now.day() <= 3) {
    return undefined
  }

  // Thu - Sun 7 am to 7 pm refresh every 10 minutes
  if (now.hour() >= 7 || now.hour() <= 19) {
    return 10 * 60 * 1000
  }

  // Otherwise refresh once an hour
  return 60 * 60 * 1000
}

const logDashboardResult = (
  data: IDashboardData,
  elapsed: number | undefined = undefined
) => {
  if (typeof elapsed !== 'undefined') {
    console.log(`Dashboard data loaded, elapsed ${(elapsed / 1000).toFixed(3)}`)
  } else {
    console.log('Dashboard data loaded')
  }
  const state = data.state
  const previous = data.previous
  const current = data.current
  const following = data.next

  console.log(`Action State: ${state.actionState}`)
  if (previous) {
    console.log(`Previous: ${previous.pool.name}`)
  } else {
    console.log('No previous pool')
  }
  if (current) {
    console.log(`Current: ${current.pool.name}`)
  } else {
    console.log('No current pool')
  }
  if (following) {
    console.log(`Next: ${following.pool.name}`)
  } else {
    console.log('No next pool')
  }
}
