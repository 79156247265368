import {
  IPoolEntryEntity,
  IPoolTeam,
  ITournamentFieldPlayer,
  IUserEntity,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { ITierPicks, ITiersPicks, ITierTeamPicks } from './types'

export const poolEntryTeamsFromTiersPicks = (
  user: IUserEntity,
  players: ITournamentFieldPlayer[],
  tiersPicks: ITiersPicks
): IPoolEntryEntity => {
  const entry: Required<Pick<IPoolEntryEntity, 'teams'>> = {
    teams: [
      {
        name: `${user.firstName} A`,
        picks: [],
      },
      {
        name: `${user.firstName} B`,
        picks: [],
      },
    ],
  }

  const fieldIndex = new Map<string, ITournamentFieldPlayer>(
    players.map((player) => [player.id, player])
  )
  const posIndex = new Map<string, number>(
    players.map((player, i) => [player.id, i + 1])
  )

  for (let teamIndex = 0; teamIndex < 2; teamIndex++) {
    const poolTeam: IPoolTeam = entry.teams[teamIndex]

    for (let tierIndex = 0; tierIndex < 3; tierIndex++) {
      const tierTeamPicks = tiersPicks[tierIndex][teamIndex]

      for (const id of tierTeamPicks.players) {
        const fieldPlayer = fieldIndex.get(id)

        if (!fieldPlayer) {
          throw new Error(`Unable to find player ${id} in field`)
        }

        const pos = posIndex.get(id)

        if (!pos) {
          throw new Error(`Unable to find position in field for player ${id}`)
        }

        poolTeam.picks.push({
          ...fieldPlayer,
          pickPageTier: tierIndex + 1,
          pickPagePos: pos,
        })
      }
    }
  }

  // console.log(`Pool entry ${JSON.stringify(entry, null, 2)}`)

  return entry
}

export const tiersPicksFromPoolEntry = (
  players: ITournamentFieldPlayer[],
  poolEntry: IPoolEntryEntity | undefined
) => {
  return [1, 2, 3].map((tier) =>
    tierPicksFromPoolEntry(tier, players, poolEntry)
  )
}

const tierPicksFromPoolEntry = (
  tier: number,
  players: ITournamentFieldPlayer[],
  poolEntry: IPoolEntryEntity | undefined
): ITierPicks => {
  const tierPicks: ITierPicks = [
    {
      complete: false,
      players: [],
    },
    {
      complete: false,
      players: [],
    },
  ]

  if (!poolEntry) {
    return tierPicks
  }

  const tierPlayers = getTierPlayers(tier, players)

  for (let i = 0; i < 2; i++) {
    const teams = poolEntry.teams as IPoolTeam[]
    const team = teams[i]
    const tierTeamPicks: ITierTeamPicks = tierPicks[i]

    try {
      tierTeamPicks.players = team.picks
        .filter((pick) => !!tierPlayers.find((p) => p.id === pick.id))
        .map((pick) => pick.id)

      tierTeamPicks.complete =
        tierTeamPicks.players.length >= getTierQuota(tier)
    } catch (e: any) {
      console.error(
        `Exception getting tier picks for tier ${tier}, team ${i}`
      )
    }
  }

  return tierPicks
}

export const getTierPlayers = (
  tier: number,
  players: ITournamentFieldPlayer[]
): ITournamentFieldPlayer[] => {
  switch (tier) {
    case 1:
      return players.slice(0, 5)
    case 2:
      return players.slice(5, 15)
    case 3:
    default:
      return players.slice(15)
  }
}

export const getTierQuota = (tier: number): number => {
  switch (tier) {
    case 1:
    case 2:
      return 2
    case 3:
    default:
      return 4
  }
}

const tierIsComplete = (tierPicks: ITierPicks): boolean => {
  const incompleteTierTeam = tierPicks.find(
    (tierTeamPicks) => !tierTeamPicks.complete
  )

  return typeof incompleteTierTeam === 'undefined'
}

export const tiersAreComplete = (tiersPicks: ITiersPicks): boolean => {
  const incompleteTier = tiersPicks.find(
    (tierPicks: ITierPicks) => !tierIsComplete(tierPicks)
  )

  return typeof incompleteTier === 'undefined'
}
