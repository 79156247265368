import API from '@aws-amplify/api'
import clonedeep from 'lodash.clonedeep'
import { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { classNames } from '../../../utils'
import { IUserContext, UserContext } from '../../app/AuthenticatedApp'
import { PickTier } from './PickTier'
import {
  getTierPlayers,
  getTierQuota,
  poolEntryTeamsFromTiersPicks,
  tiersAreComplete,
  tiersPicksFromPoolEntry,
} from './TierUtils'
import { IPickFormProps, ITierPicks, ITiersPicks } from './types'


export const PickForm = ({
  players,
  poolId,
  poolEntry,
  submitHandler,
}: IPickFormProps) => {
  const userContext = useContext(UserContext) as IUserContext
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const initialTiersPicks = tiersPicksFromPoolEntry(players, poolEntry)
  const [tiersPicks, setTiersPicks] = useState<ITiersPicks>(initialTiersPicks)
  const [picksComplete, setPicksComplete] = useState<boolean>(
    tiersAreComplete(initialTiersPicks)
  )

  // console.log(
  //   `PickForm render path=${path}, tiers state: ${JSON.stringify(
  //     tiersPicks,
  //     null,
  //     2
  //   )}`
  // )
  console.log(`PickForm render pathname=${pathname},`)

  const onSubmit = async () => {
    console.log('Submitting')
    const modifiedPoolEntry = {
      poolId,
      userId: userContext.id,
      ...poolEntryTeamsFromTiersPicks(userContext, players, tiersPicks),
    }

    console.log('Posting')
    await API.post('wgapi', '/pool/user/pick', { body: modifiedPoolEntry })
    console.log('Posted')

    submitHandler()

    navigate('..')
  }

  const onPickTierChange = (tid: number, tierPicks: ITierPicks) => {
    // console.log(
    //   `onPickChange tier: ${tid}, picks: ${JSON.stringify(tierPicks, null, 2)}`
    // )
    console.log(
      `onPickChange tier: ${tid}`
    )

    const nextTiersPicks = clonedeep(tiersPicks)

    nextTiersPicks[tid - 1] = clonedeep(tierPicks)
    setTiersPicks(nextTiersPicks)

    // console.log(`tiersPicks: ${JSON.stringify(nextTiersPicks, null, 2)}`)

    const complete = tiersAreComplete(nextTiersPicks)

    setPicksComplete(complete)

    console.log(`picksComplete state: ${complete}`)
  }

  return (
    <>
      <div className='mt-3'>
        <span
          className='inline-block font-bold'
          style={{ width: '10.5rem' }}
        ></span>{' '}
        <Link
          to='..'
          className='ml-2 px-1 rounded bg-gray-500 text-white inline-block'
        >
          Cancel
        </Link>
        <button
          onClick={onSubmit}
          className={classNames(
            'ml-1 px-1 rounded text-white inline-block',
            picksComplete ? 'bg-green-500 font-bold' : 'bg-green-300'
          )}
          disabled={!picksComplete}
        >
          Submit Picks
        </button>
      </div>
      <div>
        <PickTier
          tid={1}
          players={getTierPlayers(1, players)}
          initialValues={tiersPicks[0]}
          quota={getTierQuota(1)}
          onChange={onPickTierChange}
        />
      </div>
      <div>
        <PickTier
          tid={2}
          players={getTierPlayers(2, players)}
          initialValues={tiersPicks[1]}
          quota={getTierQuota(2)}
          onChange={onPickTierChange}
        />
      </div>
      <div>
        <PickTier
          tid={3}
          players={getTierPlayers(3, players)}
          initialValues={tiersPicks[2]}
          quota={getTierQuota(3)}
          onChange={onPickTierChange}
        />
      </div>
      <div></div>
    </>
  )
}
