import { IParsedTournament } from '@chowlett/watgolf-ddb-onetable-schema'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export interface IScheduledTournamentProps {
  tournament: IParsedTournament
}

export const ScheduledTournament = ({
  tournament,
}: IScheduledTournamentProps) => {
  const warnings: string[] = []

  for (const field of [
    'slug',
    'url',
    'timezone',
    'fedexPoints',
    'firstPlaceMoney',
  ]) {
    if (
      typeof (tournament as any)[field] === 'undefined' ||
      (tournament as any)[field] === null
    ) {
      warnings.push(field)
    }
  }
  const editPath = `edit/${tournament.tourId}`

  return (
    <div>
      <Link to={`${editPath}`}>
        {warnings.length > 0 ? (
          <FontAwesomeIcon color='red' icon={faEdit} />
        ) : (
          <FontAwesomeIcon icon={faEdit} />
        )}
      </Link>
      <span style={{ paddingLeft: '.5rem' }}>{tournament.name}</span>
      {warnings.length > 0 ? (
        <span style={{ paddingLeft: '1rem', color: 'red' }}>
          no {warnings.join(', ')}
        </span>
      ) : (
        ''
      )}
    </div>
  )
}
