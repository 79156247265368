import { IPoolEntity, ISeasonEvents } from '@chowlett/watgolf-ddb-onetable-schema'
import { useLocation } from 'react-router-dom'

import { PoolListItem } from './PoolListItem'

interface IPoolListProps {
  seasonPools: ISeasonEvents<IPoolEntity>
}

export const PoolList = ({ seasonPools }: IPoolListProps) => {
  const {pathname} = useLocation()

  console.log(`PoolList pathname=${pathname}`)
  return (
    <div>
      <div className='font-bold text-lg'>Pools for {seasonPools.season}</div>
      <div>
        {seasonPools.events.map((pool) => {
          return <PoolListItem pool={pool} key={pool.id} />
        })}
      </div>
    </div>
  )
}
