import { IPoolEntity } from '@chowlett/watgolf-ddb-onetable-schema'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Link } from 'react-router-dom'

dayjs.extend(utc)
dayjs.extend(timezone)

interface IPoolListItemProps {
  pool: IPoolEntity
}

export const PoolListItem = ({ pool }: IPoolListItemProps) => {
  // Expect admin/pools/list

  const getItemClassName = () => {
    const now = dayjs()

    if (now.isAfter(dayjs(pool.eventEndTime))) {
      // finished
      return 'text-gray-400'
    } else if (now.isBefore(pool.eventStartTime)) {
      // not started
      return ''
    } else {
      // running now
      return 'font-bold'
    }
  }

  return (
    <div className={`flex ${getItemClassName()}`}>
      <div>
        <Link to={`../../pool/${pool.id as string}`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      </div>
      <div className='pl-1 w-4 text-center'>
        {pool.isWeekly ? <FontAwesomeIcon color='green' icon={faStar} /> : ''}
      </div>
      <div className='pl-2'>{pool.name}</div>
      <div className='ml-1'>
        {dayjs(pool.eventStartTime)
          .tz('America/Toronto')
          .format('ddd MMM D YYYY')}
      </div>
    </div>
  )
}
