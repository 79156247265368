import Amplify from 'aws-amplify'
import { AuthenticatedApp } from './AuthenticatedApp'
import { Authenticator } from '@aws-amplify/ui-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import awsconfig from '../../aws-exports'

import 'log-timestamp'

import '@aws-amplify/ui-react/styles.css'
import './App.css'

Amplify.configure(awsconfig)

console.log('App starting')

const queryClient = new QueryClient()

const wgapiConfig = awsconfig.aws_cloud_logic_custom.find(
  (item) => item.name === 'wgapi'
)

if (wgapiConfig) {
  console.log(`wgapi endpoint: ${wgapiConfig.endpoint}`)
}

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <QueryClientProvider client={queryClient}>
          <AuthenticatedApp signOut={signOut} user={user} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
    </Authenticator>
  )
}

export default App
