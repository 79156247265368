import {
  IScoredPoolPlayer,
  IScoredPoolPlayerBase,
  IScoredPoolTeam,
} from '@chowlett/watgolf-ddb-onetable-schema'

const Fraction = require('fractional').Fraction

export const formatTeamPos = (scoreable: IScoredPoolTeam): string => {
  if (typeof scoreable.pos === 'undefined') {
    return ''
  }

  return `${scoreable.isTied ? 'T' : ''}${scoreable.pos}`
}

export const formatScore = (
  scoreable: IScoredPoolTeam | IScoredPoolPlayer
): string => {
  return _formatScore(scoreable.score)
}

export const formatPlayerToday = (scoreable: IScoredPoolPlayer): string => {
  const scoreableToUse = scoreable.hasOwnProperty('cutperson')
    ? (scoreable as IScoredPoolPlayer).cutperson as IScoredPoolPlayerBase
    : scoreable as IScoredPoolPlayerBase
  
  if ((scoreableToUse as IScoredPoolPlayerBase).round) {
    return _formatScore(scoreableToUse.round)
  } else if (scoreableToUse.teetime) {
    return scoreableToUse.teetime
  }
  
  return ''
}

// export const formatTeamToday = (scoreable: IScoredPoolTeam): string => {
//   const today = scoreable.hasOwnProperty('cutperson') ? ((scoreable as IScoredPoolPlayer).cutperson as IScoredPoolPlayer).round : scoreable.round

//   return _formatScore(today)
// }



export const formatThru = (
  scoreable: IScoredPoolTeam | IScoredPoolPlayer
): string => {
  const thru = scoreable.hasOwnProperty('cutperson') ? ((scoreable as IScoredPoolPlayer).cutperson as IScoredPoolPlayer).thru : scoreable.thru

  if (typeof thru === 'undefined' || thru === '') {
    return ''
  }

  let numericThru: number

  if (typeof thru === 'string') {
    const trimmed = thru.trim()
    if (trimmed === '') {
      return ''
    }

    if (!trimmed.match('^F|\\d+')) {
      // WD has thru === --
      return '--'
    }

    if (trimmed === 'F') {
      return thru
    }

    numericThru = parseInt(trimmed, 10)
  } else {
    numericThru = thru
  }

  if (numericThru === 18) {
    return 'F'
  } else {
    return new Fraction(numericThru).toString()
  }
}

export const _formatScore = (score: number | string | undefined): string => {
  const numericScore = getNumericScore(score)

  if (typeof numericScore === 'undefined') {
    return ''
  }

  if (isNaN(numericScore)) {
    return '--'
  }

  if (numericScore === 0) {
    return 'E'
  }

  if (numericScore < 0) {
    return numericScore.toString()
  }

  return `+${numericScore}`
}

export const getNumericScore = (
  score: number | string | undefined
): number | undefined => {
  if (typeof score === 'undefined') {
    return undefined
  }

  if (typeof score === 'string') {
    const trimmed = score.trim()
    if (trimmed.length === 0) {
      return undefined
    }

    if (trimmed === 'E') {
      return 0
    }

    if (!trimmed.match('^[+-]?\\d+$')) {
      // WD has score === '--'
      return NaN
    }

    return parseInt(trimmed, 10)
  }

  return score
}
