import API from '@aws-amplify/api'
import {
  ISeasonEvents,
  ITournamentEntity,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { ScheduleDisplay } from './components/schedule/ScheduleDisplay'
import { SchedulePrompt } from './components/schedule/SchedulePrompt'
import { TournamentList } from './components/tournament-list/TournamentList'

export const Tournaments = () => {
  const [seasonTournaments, setSeasonTournaments] =
    useState<ISeasonEvents<ITournamentEntity>>()
  const { pathname } = useLocation()

  console.log(`Tournaments.tsx path=name${pathname}`)

  useEffect(() => {
    const loadTournaments = async () => {
      try {
        const result = await API.get('wgapi', '/admin/tournaments', {})

        console.log(`/admin/tournaments: ${JSON.stringify(result, null, 2)}`)

        setSeasonTournaments(result.data)
      } catch (e) {
        console.log(`Error while loading tournaments: ${e}`)
        setSeasonTournaments({ season: 'unknown season', events: [] })
        return
      }
    }

    if (typeof seasonTournaments === 'undefined') {
      loadTournaments()
    }
  })

  if (typeof seasonTournaments === 'undefined') {
    return <div>Loading...</div>
  }

  console.log(`tournaments.length=${seasonTournaments.events.length}}`)

  return (
    <Routes>
      <Route
        path=''
        element={
          <Navigate
            to={seasonTournaments.events.length > 0 ? `list` : `import/prompt`}
          />
        }
      />
      <Route
        path={'list'}
        element={<TournamentList seasonTournaments={seasonTournaments} />}
      />
      <Route path={'import/prompt'} element={<SchedulePrompt />} />
      <Route path={'import/display'} element={<ScheduleDisplay />} />
    </Routes>
  )
}
