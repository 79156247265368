import { CognitoUser } from '@aws-amplify/auth'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { IUserEntity } from '@chowlett/watgolf-ddb-onetable-schema'
import { API, Auth } from 'aws-amplify'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom'

import { AdminHome } from '../admin/AdminHome'
import { UserHome } from '../user/UserHome'
import { Header } from './Header'

interface CognitoUserAmplify extends CognitoUser {
  username?: string
}
interface IAuthenticatedAppProps {
  signOut: (data?: Record<string | number | symbol, any>) => void
  user: CognitoUserAmplify
}
interface ICognitoContext {
  cognitoId: string
  email: string
  groups: string[]
}

export type IUserContext = ICognitoContext & IUserEntity

export const UserContext = React.createContext<IUserContext | undefined>(
  undefined
)

export const AuthenticatedApp = (props: IAuthenticatedAppProps) => {
  console.log('Authenticated app render')

  const [userContext, setUserContext] = React.useState<IUserContext>()

  React.useEffect(() => {
    if (!userContext) {
      ;(async () => {
        try {
          setUserContext(await loadUserContext())
        } catch (e) {
          // console.log(`AuthenticatedApp useEffect: loadUserContext error: ${e}`)
        }
      })()
    }

    return onAuthUIStateChange((_, authData) => {
      if (authData) {
        try {
          const cognitoContext = getCognitoContextFromUser(
            authData as CognitoUser
          )

          if (cognitoContext) {
            // console.log(`Cognito context: ${JSON.stringify(cognitoContext, null, 2)}`)
            API.get(
              'wgapi',
              `/pool/user?cognitoId=${cognitoContext?.cognitoId}`,
              {}
            )
              .then((value: any) => {
                // console.log(`user data from cognito id: ${JSON.stringify(value, null, 2)}`)
                setUserContext({ ...cognitoContext, ...value.data })
              })
              .catch((reason: any) => {
                console.error(
                  `Error getting user data from cognito email: ${JSON.stringify(
                    reason,
                    null,
                    2
                  )}`
                )
                setUserContext(cognitoContext)
              })
          }
        } catch (e) {
          console.error(
            `AuthenticatedApp onAuthUIStateChange: getCognitoContextFromUser error: ${e}`
          )
          setUserContext(undefined)
        }
      } else {
        console.error(`onAuthUIStateChange called with no authData`)
        setUserContext(undefined)
      }
    })
  }, [userContext])

  if (!userContext) {
    return <div></div>
  }

  console.log(
    `AuthenticatedApp userContext: ${userContext.firstName} ${userContext.lastName}, cognito ${userContext.cognitoId}`
  )

  console.log(`Environment: ${JSON.stringify(process.env, null, 2)}`)

  const queryClient = new QueryClient()

  return (
    <UserContext.Provider value={userContext}>
      <QueryClientProvider client={queryClient}>
        <div className='container mt-2 ml-2'>
          <Router>
            <Header />
            <Routes>
              <Route
                path='/'
                element={
                  <Navigate
                    to={
                      userContext?.groups.includes('admin') ? '/admin' : '/pool'
                    }
                  />
                }
              />
              <Route path='/admin/*' element={<AdminHome />} />
              <Route path='/pool/*' element={<UserHome />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </Router>
        </div>
      </QueryClientProvider>
    </UserContext.Provider>
  )
}

const loadUserContext = async (): Promise<IUserContext | undefined> => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const cognitoContext = getCognitoContextFromUser(cognitoUser)

  if (!cognitoContext) {
    return undefined
  }

  const result = await API.get(
    'wgapi',
    `/pool/user?cognitoId=${cognitoContext.cognitoId}`,
    {}
  )

  if (!result || !result.data) {
    return undefined
  }

  return { ...cognitoContext, ...result.data }
}

const getCognitoContextFromUser = (
  user: CognitoUser
): ICognitoContext | undefined => {
  if (user) {
    // console.log(`cognito user: ${JSON.stringify(user, null, 2)}`)
    const cognitoId = (user as any).signInUserSession.idToken.payload.sub
    const email = (user as any).signInUserSession.idToken.payload.email
    if (!email) {
      return
    }
    const groups = (user as any).signInUserSession.accessToken.payload[
      'cognito:groups'
    ]

    return { cognitoId, email, groups: groups || [] }
  }
}

const NotFound = () => {
  const { pathname } = useLocation()
  const msg = `Not found: path=${pathname}`

  console.log(msg)

  return <div></div>
}
