import { ITournamentEntity } from '@chowlett/watgolf-ddb-onetable-schema'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

interface ITournamentListItemProps {
  tournament: ITournamentEntity
}

export const TournamentListItem = ({
  tournament,
}: ITournamentListItemProps) => {
  const warnings: string[] = []

  for (const field of [
    'slug',
    'url',
    'timezone',
    'fedexPoints',
    'firstPlaceMoney',
  ]) {
    if (
      typeof (tournament as any)[field] === 'undefined' ||
      (tournament as any)[field] === null
    ) {
      warnings.push(field)
    }
  }

  const getItemClassName = () => {
    const now = dayjs()

    if (now.isAfter(dayjs(tournament.eventEndTime))) {
      // finished
      return 'text-gray-400'
    } else if (now.isBefore(tournament.eventStartTime)) {
      // not started
      return ''
    } else {
      // running now
      return 'font-bold'
    }
  }

  return (
    <div className={`flex ${getItemClassName()}`}>
      <div>
        <span>
          {warnings.length > 0 ? (
            <FontAwesomeIcon color='red' icon={faEdit} />
          ) : (
            <FontAwesomeIcon icon={faEdit} />
          )}
        </span>
        <span style={{ paddingLeft: '.5rem' }}>{tournament.name}</span>
        {warnings.length > 0 ? (
          <span style={{ paddingLeft: '1rem', color: 'red' }}>
            no {warnings.join(', ')}
          </span>
        ) : (
          ''
        )}
      </div>
      <div className='ml-1'>
        {dayjs(tournament.eventStartTime)
          .tz('America/Toronto')
          .format('ddd MMM D YYYY H:mm')}
      </div>
    </div>
  )
}
