import {
  IPoolEntity,
  ISeasonEvents,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { API } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { PoolList } from './components/PoolList'

export const Pools = () => {
  const [seasonPools, setSeasonPools] = useState<ISeasonEvents<IPoolEntity>>()
  const { pathname } = useLocation()

  console.log(`Pools.tsx pathname=${pathname}`)

  useEffect(() => {
    const loadPools = async () => {
      try {
        const result = await API.get('wgapi', '/admin/pools', {})

        console.log(`/admin/pools: ${JSON.stringify(result, null, 2)}`)

        setSeasonPools(result.data)
      } catch (e) {
        console.log(`Error while loading pools: ${e}`)
        setSeasonPools({ season: 'unknown season', events: [] })
        return
      }
    }

    if (typeof seasonPools === 'undefined') {
      loadPools()
    }
  })

  if (typeof seasonPools === 'undefined') {
    return <div>Loading...</div>
  }

  console.log(`pools.length=${seasonPools.events.length}`)

  return (
    <Routes>
      <Route path='list' element={<PoolList seasonPools={seasonPools} />} />
      <Route path='*' element={<Navigate to='list' />} />
    </Routes>
  )
}
