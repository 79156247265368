import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ITournamentEntity } from '@chowlett/watgolf-ddb-onetable-schema'

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatTournamentDates = (
  tournament: ITournamentEntity
): string => {
  const startTime = dayjs(tournament.eventStartTime).tz('America/Toronto')
  const endTime = startTime.add(3, 'day')
  const format = 'ddd MMM D'

  return `${startTime.format(format)} through ${endTime.format(format)}`
}
