import {
  ICurrentPool,
  IPoolLeaderboardData,
  PoolUserActionState,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { Leaderboard } from '../../leaderboard'
import { PickCta } from '../../pick'

export interface ICurrentPoolProps {
  actionState: PoolUserActionState
  poolPlus: ICurrentPool | undefined
}

export const CurrentPool = ({ actionState, poolPlus }: ICurrentPoolProps) => {
  console.log('Current pool render')
  if (!poolPlus) {
    return (
      <div>
        <div className='font-bold text-xl'>This Week</div>
        <div>No pool this week</div>
      </div>
    )
  }

  if (actionState === 'idle') {
    return (
      <div>
        <Header />
        <div>{poolPlus.pool.name}</div>
        <div>Entries are not open yet. Entries open at 6:00 pm today.</div>
      </div>
    )
  }

  if (actionState === 'pick') {
    return (
      <div>
        <Header />
        <PickCta
          tournament={poolPlus.tournament}
          poolEntry={poolPlus.poolEntry}
        />
      </div>
    )
  }

  // actionState is 'play'
  console.log('CurrentPool rendering leaderboard')

  // const leaderboard:IPoolLeaderboard = {
  //   poolId: poolPlus.pool.id as string,
  //   poolName: poolPlus.pool.name as string,
  //   time: poolPlus.snapshot ? poolPlus.snapshot.playerScores.

  // }

  const snapshot = poolPlus.snapshot

  if (!snapshot) {
    return (
      <div>
        Play should have started by now, but no scores are available yet
      </div>
    )
  }

  const assembleLeaderboardData = (): IPoolLeaderboardData => {
    return {
      poolId: poolPlus.pool.id as string,
      poolName: poolPlus.pool.name as string,
      snapshot,
    }
  }

  return (
    <div>
      <Header />
      <div>{poolPlus.pool.name}</div>
      <Leaderboard leaderboardData={assembleLeaderboardData()} />
    </div>
  )
}

const Header = () => {
  return <div className='font-bold text-xl'>This Week</div>
}
