import { IParsedTournament } from '@chowlett/watgolf-ddb-onetable-schema'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { IFieldDef, IFieldDefDefaultable, renderFieldDef } from '../../../../../utils'
// import * as Yup from 'yup'

import { SelectTimezoneField } from './SelectTimezoneField'

interface IScheduleTournamentEditProps {
  tournaments: IParsedTournament[]
  submitHandler: (values: any) => void
}

interface FormValues extends IParsedTournament {}

interface OtherProps {}

const fieldDefDefaults: IFieldDefDefaultable = {
  labelClassName: 'font-bold pr-1 w-24',
  fieldType: 'input',
  errorClassName: 'text-red-500',
}

const fieldDefs: IFieldDef[] = [
  {
    name: 'name',
    label: 'Name',
    fieldClassName: 'w-112',
  },
  {
    name: 'tourId',
    label: 'Tour Id',
    fieldClassName: 'w-40',
  },
  {
    name: 'season',
    label: 'Season',
    fieldClassName: 'w-40',
  },
  {
    name: 'slug',
    label: 'Slug',
    fieldClassName: 'w-112',
  },
  {
    name: 'eventWeek',
    label: 'Week',
    fieldClassName: 'w-40',
  },
  {
    name: 'eventStartTime',
    label: 'Start Time',
    fieldClassName: 'w-64',
  },
  {
    name: 'eventEndTime',
    label: 'End Time',
    fieldClassName: 'w-64',
  },
  {
    name: 'timezone',
    label: 'Time Zone',
    fieldComponent: SelectTimezoneField,
  },
  {
    name: 'enabled',
    label: 'Enabled',
    fieldClassName: 'w-40',
  },
  {
    name: 'location',
    label: 'Location',
    fieldClassName: 'w-160 h-28 align-top whitespace-pre-wrap',
    fieldComponent: 'textarea',
  },
  {
    name: 'url',
    label: 'URL',
    fieldClassName: 'w-160',
  },
  {
    name: 'purse',
    label: 'Purse',
    fieldClassName: 'w-40',
  },
  {
    name: 'firstPlaceMoney',
    label: 'First Place',
    fieldClassName: 'w-40',
  },
  {
    name: 'fedexPoints',
    label: 'Fedex',
    fieldClassName: 'w-40',
  },
]

const ScheduleTournamentEditInner = (
  props: OtherProps & FormikProps<FormValues>
) => {
  const { touched, errors, isSubmitting } = props

  return (
    <Form>
      {/* Fields */}
      {fieldDefs.map((fieldDef) =>
        renderFieldDef(fieldDef, fieldDefDefaults, touched, errors)
      )}

      {/* Buttons */}
      <div className='mt-2 flex'>
        <div className='flex-grow' />
        <Link
          to='../..'
          className='bg-gray-500 text-white px-2 py-0.5 rounded'
        >
          Cancel
        </Link>
        <button
          type='submit'
          disabled={isSubmitting}
          className='ml-2 bg-green-500 text-white px-2 py-0.5 rounded'
        >
          Submit
        </button>
      </div>
    </Form>
  )
}

interface ScheduleTournamentEditWrapperProps {
  tournament: IParsedTournament
  submitHandler: (modified: IParsedTournament) => void
}

export const ScheduleTournamentEdit = ({
  tournaments,
  submitHandler,
}: IScheduleTournamentEditProps) => {
  const { tourId } = useParams<{ tourId: string }>()

  console.log(`ScheduleTournamentEdit rendering for ${tourId}`)

  const ScheduleTournamentEditWrapper = withFormik<
    ScheduleTournamentEditWrapperProps,
    FormValues
  >({
    // Transform outer props into form values
    mapPropsToValues: (props) => {
      return {
        ...props.tournament,
      }
    },

    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {}
      if (!values.name) {
        errors.name = 'Required'
      }
      if (!values.timezone) {
        errors.timezone = 'Required'
      }

      return errors
    },

    handleSubmit: submitHandler,
  })(ScheduleTournamentEditInner)

  const tournament = tournaments.find(
    (tournament) => tournament.tourId === tourId
  )

  return (
    <div
      className='absolute left-0 top-0 z-10 bg-white border-solid border-2 border-green-500 p-2'
      style={{ width: '48rem' }}
    >
      <div className='text-green-700 font-bold text-xl'>Edit</div>
      <ScheduleTournamentEditWrapper
        tournament={tournament as IParsedTournament}
        submitHandler={submitHandler}
      />
    </div>
  )
}
