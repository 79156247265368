import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import {
  IPoolPick,
  IPoolTeam,
  ITournamentEntity,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { Link } from 'react-router-dom'
import { IPickProps } from './types'

dayjs.extend(utc)
dayjs.extend(timezone)

export const PickCta = ({ tournament, poolEntry }: IPickProps) => {
  if (poolEntry) {
    const teams = poolEntry.teams as IPoolTeam[]

    return (
      <div>
        <div>You've entered these teams:</div>

        {<PickCtaTeams teams={teams} />}

        <ChangePicksMessage tournament={tournament} />

        <Link
          className='mt-1 ml-4 px-1 rounded bg-green-500 text-white'
          to='pick'
        >
          Change Picks
        </Link>
      </div>
    )
  }
  return (
    <div>
      <div>You haven't entered any teams yet</div>
      <Link
        className='mt-1 ml-4 px-1 rounded bg-green-500 text-white'
        to='pick'
      >
        Pick
      </Link>
    </div>
  )
}

const PickCtaTeams = ({ teams }: { teams: IPoolTeam[] }) => {
  return (
    <div className='ml-4 flex flex-col w-full text-xs'>
      {teams.map((team) => (
        <PickCtaTeam key={team.name} team={team} />
      ))}
    </div>
  )
}

const PickCtaTeam = ({ team }: { team: IPoolTeam }) => {
  return (
    <div className='flex'>
      <div className='p-1 w-20 font-bold'>{team.name}</div>
      {team.picks.map((pick: IPoolPick) => {
        return (
          <div key={pick.id} className='p-1 w-20'>
            {pick.lastName}
          </div>
        )
      })}
    </div>
  )
}

interface IChangePicksMessageProps {
  tournament: ITournamentEntity
}

const ChangePicksMessage = ({ tournament }: IChangePicksMessageProps) => {
  const startTime = dayjs(tournament.eventStartTime).tz('America/Toronto')
  const now = dayjs().tz('America/Toronto')

  const displayStart = startTime.startOf('day').isSame(now.startOf('day'))
    ? `${startTime.format('h:mm a')} today`
    : startTime.format('ddd h:mm a')

  return (
    <div>
      You can change your picks at any time up to {displayStart}, when play
      starts
    </div>
  )
}
