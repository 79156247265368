import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

dayjs.extend(utc)
dayjs.extend(timezone)

interface ILeaderboardHeaderProps {
  time: string
}

export const LeaderboardHeader = ({ time }: ILeaderboardHeaderProps) => {
  const asof = dayjs(time).tz('America/Toronto')
  const now = dayjs().tz('America/Toronto')

  let format = ''

  if (asof.year() !== now.year()) {
    format = 'ddd MMM D YYYY'
  } else if (asof.month() !== now.month()) {
    format = 'ddd MMM D'
  } else if (asof.day() !== now.day()) {
    format = 'ddd MMM D'
  }

  format += ' h:mm A'

  const formatted = asof.format(format)

  return (
    <div className='flex'>
      <div>
        <span>Leaderboard as of</span>
        <span>{formatted}</span>
      </div>
      <div className='ml-4'>
        <Link to='/'>
          <FontAwesomeIcon icon={faRedo} />
        </Link>
      </div>
    </div>
  )
}
