import clonedeep from 'lodash.clonedeep'
import { useState } from 'react'

import { classNames } from '../../../utils/ClassNames'
import { IPickTierProps, ITierPicks } from './types'

const tierColors = ['bg-green-300', 'bg-blue-200', 'bg-gray-200']

export const PickTier = ({
  players,
  initialValues,
  quota,
  tid,
  onChange,
}: IPickTierProps) => {
  // console.log(`Tier ${tid} initial values: ${JSON.stringify(initialValues, null, 2)}`)
  const [picks, setPicks] = useState<ITierPicks>(initialValues)


  const playerPicked = (teamIndex:number, playerId:string):boolean => {
      const teamPicks = picks[teamIndex]

      return teamPicks.players.includes(playerId)
  }

  const onPickChange = (e: any) => {
    const picked = e.target.checked
    const parts = e.target.name.split('-')
    const teamLabel = parts[0]
    const teamIndex = teamLabel === 'A' ? 0 : 1
    const id = parts[1]

    // console.log(`team: ${teamLabel}, id: ${id}, picked: ${picked}`)

    const nextTierPicks = clonedeep(picks)
    const nextTierTeam = (nextTierPicks as any)[teamIndex]
    const teamPlayers = nextTierTeam.players as string[]

    if (picked) {
      if (teamPlayers.includes(id)) {
        console.warn(`You can't pick ${id}. Already picked for '${teamIndex}'`)
      } else {
        teamPlayers.push(id)
        // console.log(`${id} added to tier ${tid} ${teamIndex}`)
      }
    } else {
      if (!teamPlayers.includes(id)) {
        console.warn(`You can't unpick ${id}. Wasn't picked for '${teamIndex}'`)
      } else {
        const index = teamPlayers.indexOf(id)

        if (index > -1) {
          teamPlayers.splice(index, 1)
          // console.log(`${id} removed from tier ${tid} ${teamIndex}`)

        } else {
          // console.log(
          //   `Javascript insanity. ${id} is found by 'includes' but not by 'indexOf'`
          // )
        }
      }
    }

    nextTierTeam.complete = nextTierTeam.players.length >= quota

    setPicks(nextTierPicks)

    onChange(tid, nextTierPicks)
  }

  const teamComplete = (teamIndex: number) => {
    return picks[teamIndex].complete
  }

  const teamPlayerPicked = (teamIndex: number, id: string): boolean => {
    return picks[teamIndex].players.includes(id)
  }

  return (
    <div
      className={classNames('mt-1 p-2 rounded', tierColors[tid - 1])}
      style={{ width: '22rem' }}
    >
      <div className='font-bold'>Pick {quota} players for each team</div>
      <div className='flex font-bold'>
        <div className='w-44'>Name</div>
        <div className='w-12 text-center'>WR</div>
        <div className='w-12 text-center'>FR</div>
        <div className='w-8 text-center'>A</div>
        <div className='w-8 text-center'>B</div>
      </div>
      {players.map((player) => {
        return (
          <div key={player.id} className='flex'>
            <div className='w-44'>
              {player.firstName} {player.lastName}
            </div>
            <div className='w-12 text-center'>
              {player.owgr ? player.owgr.pos : ''}
            </div>

            <div className='w-12 text-center'>
              {player.fedex ? player.fedex.pos : ''}
            </div>
            <div className='w-8 text-center'>
              <input
                type='checkbox'
                name={`A-${player.id}`}
                checked={playerPicked(0, player.id)}
                disabled={teamComplete(0) && !teamPlayerPicked(0, player.id)}
                onChange={onPickChange}
              />
            </div>
            <div className='w-8 text-center'>
              <input
                type='checkbox'
                name={`B-${player.id}`}
                checked={playerPicked(1, player.id)}
                disabled={teamComplete(1) && !teamPlayerPicked(1, player.id)}
                onChange={onPickChange}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
