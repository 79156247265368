import { IScoredPoolPlayer } from "@chowlett/watgolf-ddb-onetable-schema";
import { formatScore, formatThru, formatPlayerToday } from "./Formatters";

interface IExpandedTeamPlayerProps {
  playerScore: IScoredPoolPlayer | undefined
}

export const ExpandedTeamPlayer = ({playerScore}:IExpandedTeamPlayerProps) => {
  if (!playerScore) {
    return <></>
  }

  // console.log(`${playerScore.firstName} ${playerScore.lastName}: ${JSON.stringify(playerScore, null, 2)}`)

  return (
    <div className='flex text-xs'>
      <div className='ml-8 w-8 text-center'>{playerScore.pos}</div>
      <div className='w-48 ml-2'>{playerScore.firstName} {playerScore.lastName}</div>
      <div className='w-16 text-center'>{formatScore(playerScore)}</div>
      <div className='w-16 text-center'>{formatPlayerToday(playerScore)}</div>
      <div className='w-16 text-center'>{formatThru(playerScore)}</div>
    </div>
  )
}