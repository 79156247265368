import { IPreviousPool } from '@chowlett/watgolf-ddb-onetable-schema'
import { formatTournamentDates } from '../utils/Utils'

export interface IPreviousPoolProps {
  poolPlus: IPreviousPool | undefined
}

export const PreviousPool = ({ poolPlus }: IPreviousPoolProps) => {
  if (!poolPlus) {
    return (
      <div>
        <div className='font-bold text-xl'>Previous Pool</div>
        <div>No previous pools this season</div>
      </div>
    )
  }

  return (
    <div>
      <div className='font-bold text-xl'>Previous Pool</div>
      <div>
        {poolPlus.pool.name} - {formatTournamentDates(poolPlus.tournament)}
      </div>
    </div>
  )
}
