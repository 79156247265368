import {
  IParsedSchedule,
  IParsedTournament,
  IPoolAttributes,
  ITournamentAttributes,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { ScheduledTournament } from './ScheduledTournament'
import { ScheduleTournamentEdit } from './ScheduleTournamentEdit'

dayjs.extend(utc)
dayjs.extend(timezone)

export const ScheduleDisplay = () => {
  const [schedule, setSchedule] = useState<IParsedSchedule | undefined>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  console.log(`ScheduleDisplay path=${pathname}`)

  useEffect(() => {
    const scrape = async () => {
      const response = await API.get('wgapi', '/admin/import', {})

      console.log(`import response: ${JSON.stringify(response, null, 2)}`)

      setSchedule(response.data)
    }

    if (!schedule) {
      scrape()
    }
  })

  const poolForTournament = (
    tournament: ITournamentAttributes
  ): IPoolAttributes => {
    const eventStartTime = dayjs(tournament.eventStartTime)
      .tz('America/Toronto')
      .startOf('day')
      .day(1)
      .toISOString()
    const eventEndTime = dayjs(tournament.eventEndTime)
      .tz('America/Toronto')
      .toISOString()
    const isWeekly =
      (typeof tournament.fedexPoints !== 'undefined' &&
        tournament.fedexPoints >= 550) ||
      tournament.tourId === 'r060'

    return {
      id: uuidv4(),
      tournamentId: tournament.id,
      name: tournament.name,
      season: tournament.season,
      eventWeek: tournament.eventWeek,
      eventStartTime,
      eventEndTime,
      enabled: true,
      finished: '',
      isWeekly,
    }
  }

  const handleSave = async () => {
    console.log('Saving ...')

    if (!schedule) {
      return
    }

    for (let i = 0; i < schedule.events.length; i++) {
      const parsedTournament = schedule.events[i]

      console.log(
        `Saving ${i + 1}/${schedule.events.length} '${parsedTournament.name}'`
      )

      const id = uuidv4()
      const tournament: ITournamentAttributes = { ...parsedTournament, id }

      await API.post('wgapi', '/admin/tournament', {
        body: tournament,
      })

      const pool = poolForTournament(tournament)

      await API.post('wgapi', '/admin/pool', {
        body: pool,
      })
    }
  }

  const handleDone = () => {
    //
  }

  const handleEditSubmit = (modified: IParsedTournament) => {
    console.log(`Handling edit save for ${modified.tourId} '${modified.name}'`)

    const theSchedule = schedule as IParsedSchedule

    const index = theSchedule.events.findIndex(
      (tournament) => tournament.tourId === modified.tourId
    )

    if (index >= 0) {
      theSchedule.events[index] = { ...modified }

      navigate(pathname)
    } else {
      throw new Error('Unable to find edited tournament')
    }
  }

  if (!schedule) {
    return <div>Loading ...</div>
  }

  const count = schedule.events.length

  if (count > 0) {
    const season = schedule.season
    const editRoute = 'edit/:tourId'
    console.log(`ScheduleDisplay editRoute=${editRoute}`)

    return (
      <div className='absolute'>
        <div>
          <div className='flex'>
            <span>{`We found ${count} pool-ready tournament${
              count === 1 ? '' : 's'
            } scheduled for ${season}`}</span>
            <button
              className='ml-2 p-1 rounded bg-green-500 text-white'
              onClick={handleSave}
            >
              Save
            </button>
          </div>
          {schedule.events.map((tournament) => {
            return (
              <ScheduledTournament
                tournament={tournament}
                key={tournament.tourId}
              />
            )
          })}
        </div>
        <Routes>
          <Route
            path={editRoute}
            element={
              <ScheduleTournamentEdit
                tournaments={schedule.events}
                submitHandler={handleEditSubmit}
              />
            }
          />
        </Routes>
      </div>
    )
  } else {
    return (
      <div className='flex'>
        <div>We found no pool-ready tournaments scheduled</div>
        <button
          className='ml-2 p-1 rounded bg-green-500 text-white'
          onClick={handleDone}
        >
          Save
        </button>
      </div>
    )
  }
}
