export interface IScoreable {
  score?: number | undefined
  hole?: number | undefined
}

export const compareScores = (
  a: IScoreable | undefined,
  b: IScoreable | undefined,
  nameMethod: (player: IScoreable) => string
): number => {
  if (!a || !b) {
    if (!a && !b) {
      return 0
    }

    return !a ? 1 : -1
  }

  const scorea = a.score
  const scoreb = b.score

  if (typeof scorea === 'undefined' || typeof scoreb === 'undefined') {
    if (typeof scorea === 'undefined' && typeof scoreb === 'undefined') {
      return compareHoles(a, b, nameMethod)
    }

    return typeof scorea === 'undefined' ? 1 : -1
  }

  if (scorea !== scoreb) {
    return scorea - scoreb
  }

  return compareHoles(a, b, nameMethod)
}

export const compareHoles = (
  a: IScoreable,
  b: IScoreable,
  nameMethod: (player: IScoreable) => string
): number => {
  const holea = a.hole
  const holeb = b.hole

  if (typeof holea === 'undefined' || typeof holeb === 'undefined') {
    if (typeof holea === 'undefined' && typeof holeb === 'undefined') {
      return compareNames(a, b, nameMethod)
    }

    return typeof holea === 'undefined' ? 1 : -1 // on the course sorts earlier
  }

  if (holea === holeb) {
    return compareNames(a, b, nameMethod)
  }

  return holeb - holea // further hole sorts earlier
}

export const compareNames = (
  a: IScoreable,
  b: IScoreable,
  nameMethod: (player: IScoreable) => string
): number => {
  return nameMethod(a).localeCompare(nameMethod(b))
}
