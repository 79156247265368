import { ISeasonEvents, ITournamentEntity } from '@chowlett/watgolf-ddb-onetable-schema'

import { TournamentListItem } from './TournamentListItem'

interface ITournamentListProps {
  seasonTournaments: ISeasonEvents<ITournamentEntity>
}

export const TournamentList = ({ seasonTournaments }: ITournamentListProps) => {
  return (
    <div>
      <div className='font-bold text-lg'>
        Tournaments for {seasonTournaments.season}
      </div>
      <div>
        {seasonTournaments.events.map((tournament) => {
          return (
            <TournamentListItem
              tournament={tournament}
              key={tournament.tourId}
            />
          )
        })}
      </div>
    </div>
  )
}
