import { Link } from 'react-router-dom'

export const SchedulePrompt = () => {
  return (
    <div className='flex mt-3'>
      <div>No tournaments found for this season</div>
      <Link
        className='ml-2 p-1 rounded bg-green-500 text-white'
        to='../display'
      >
        Import Schedule
      </Link>
    </div>
  )
}
