import React from 'react'

export interface IFieldDefDefaultable {
  label?: string | undefined
  labelClassName?: string | undefined
  fieldType?: string | undefined
  fieldClassName?: string | undefined
  fieldComponent?: React.FC<any> | string | undefined
  errorClassName?: string | undefined
}

export interface IFieldDef extends IFieldDefDefaultable {
  name: string
}

export interface ILabelProps {
  className?: string
}

export interface IFieldProps {
  type?: string
  component?: React.FC<any> | string
  className?: string
}

export interface IErrorProps {
  className?: string
}

export const getLabelProps = (
  fieldDef: IFieldDef,
  defaults: IFieldDefDefaultable
): ILabelProps => {
  const props: ILabelProps = {}
  if (fieldDef.hasOwnProperty('labelClassName')) {
    if (fieldDef.labelClassName) {
      props.className = fieldDef.labelClassName
    }
  } else if (defaults.hasOwnProperty('labelClassName')) {
    props.className = defaults.labelClassName
  }

  return props
}

export const getFieldProps = (
  fieldDef: IFieldDef,
  defaults: IFieldDefDefaultable
): IFieldProps => {
  const props: IFieldProps = {}
  if (fieldDef.hasOwnProperty('fieldType')) {
    if (fieldDef.fieldType) {
      props.type = fieldDef.fieldType
    }
  } else if (defaults.hasOwnProperty('fieldType')) {
    props.type = defaults.fieldType
  }
  if (fieldDef.hasOwnProperty('fieldClassName')) {
    if (fieldDef.fieldClassName) {
      props.className = fieldDef.fieldClassName
    }
  } else if (defaults.hasOwnProperty('fieldClassName')) {
    props.className = defaults.fieldClassName
  }
  if (fieldDef.hasOwnProperty('fieldComponent')) {
    if (fieldDef.fieldComponent) {
      props.component = fieldDef.fieldComponent
    }
  } else if (defaults.hasOwnProperty('fieldComponent')) {
    props.component = defaults.fieldComponent
  }

  return props
}

export const getErrorProps = (
  fieldDef: IFieldDef,
  defaults: IFieldDefDefaultable
): IErrorProps => {
  const props: IErrorProps = {}
  if (fieldDef.hasOwnProperty('errorClassName')) {
    if (fieldDef.errorClassName) {
      props.className = fieldDef.errorClassName
    }
  } else if (defaults.hasOwnProperty('errorClassName')) {
    props.className = defaults.errorClassName
  }

  return props
}
