import { UserDropdown } from './UserDropdown'
import { WatgolfLogo } from './WatgolfLogo'

export const Header = () => {
  return (
    <div className='flex'>
      <div className='flex-1'>
        <WatgolfLogo />
      </div>
      <div>
        <UserDropdown />
      </div>
    </div>
  )
}
