import { FieldProps } from 'formik'
import Select, { CommonProps, GroupBase } from 'react-select'
import timezones from 'timezones.json'

export const SelectTimezoneField = ({
  field,
  form,
}: CommonProps<unknown, false, GroupBase<unknown>> & FieldProps) => {
  const iana = Array.from(
    new Set(
      timezones
        .map((tz) => tz.utc)
        .flat()
        .sort()
    )
  ).map((iana_name) => {
    return {
      value: iana_name,
      label: iana_name,
    }
  })

  return (
    <div style={{ width: '20rem' }}>
      <Select
        options={iana}
        name={field.name}
        value={iana.find((option) => option.value === field.value)}
        onChange={(option: any) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
      />
    </div>
  )
}
