import {
  IScoredPoolPlayer,
  IScoredPoolPlayers,
  IScoredPoolTeam,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { ExpandedTeamPlayer } from './ExpandedTeamPlayer'
import { compareScores, IScoreable } from './Helpers'

interface IExpandedTeamProps {
  playerScores: IScoredPoolPlayers
  teamScore: IScoredPoolTeam
}

export const ExpandedTeam = ({
  playerScores,
  teamScore,
}: IExpandedTeamProps) => {
  const playerScoreIndex = new Map<string, IScoredPoolPlayer>(
    playerScores.map((playerScore) => [playerScore.id, playerScore])
  )
  const sortedPlayers = teamScore.players.sort((ida, idb) => {
    const playera = playerScoreIndex.get(ida)
    const playerb = playerScoreIndex.get(idb)

    const nameMethod = (player: IScoreable) =>
      `${(player as IScoredPoolPlayer).lastName.padEnd(64)}${
        (player as IScoredPoolPlayer).firstName
      }`

    return compareScores(playera, playerb, nameMethod)
  })

  return (
    <div>
      {sortedPlayers.map((playerId) => (
        <ExpandedTeamPlayer
          key={playerId}
          playerScore={playerScoreIndex.get(playerId)}
        />
      ))}
    </div>
  )
}
