import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  IPoolLeaderboardData,
  IScoredPoolPlayer,
  IScoredPoolTeam,
  IScoredPoolTeams,
} from '@chowlett/watgolf-ddb-onetable-schema'
import { useState } from 'react'
import { ExpandedTeam } from './ExpandedTeam'
import {
  formatTeamPos,
  formatScore,
  formatThru,
  getNumericScore,
  _formatScore,
} from './Formatters'
import { LeaderboardHeader } from './LeaderboardHeader'
import { useLocation } from 'react-router-dom'

export interface ILeaderboardProps {
  leaderboardData: IPoolLeaderboardData
}

type ExpandAction = 'close' | 'expand'
type TeamsFilter = 'me' | 'top10' | 'all'

interface ITeamsFilterConfig {
  value: TeamsFilter
  label: string
}

const filtersConfigs: ITeamsFilterConfig[] = [
  {
    value: 'me',
    label: 'Me',
  },
  {
    value: 'top10',
    label: 'Top 10',
  },
  {
    value: 'all',
    label: 'All',
  },
]

export const Leaderboard = ({ leaderboardData }: ILeaderboardProps) => {
  const { pathname } = useLocation()

  console.log(`Leaderboard render path=${pathname}`)

  const [expanded, setExpanded] = useState<Map<string, boolean>>(
    new Map<string, boolean>(
      leaderboardData.snapshot.teamScores.map((teamScore) => [
        teamScore.teamName,
        false,
      ])
    )
  )
  const [activeTeamsFilter, setActiveTeamsFilter] = useState<TeamsFilter>('me')

  const someExpanded = (): boolean => {
    const firstExpanded = Array.from(expanded.values()).find((value) => value)

    return typeof firstExpanded !== 'undefined'
  }

  const toggleExpandAll = (action: ExpandAction) => {
    const modifiedExpand = new Map<string, boolean>(
      Array.from(expanded.keys()).map((key) => [key, action === 'expand'])
    )
    setExpanded(modifiedExpand)
  }

  const toggleExpandTeam = (teamName: string) => {
    console.log(`Expand clicked for ${teamName}`)

    const modifiedExpand = new Map<string, boolean>(
      Array.from(expanded.entries())
    )
    if (modifiedExpand.has(teamName)) {
      modifiedExpand.set(teamName, !modifiedExpand.get(teamName))

      setExpanded(modifiedExpand)

      console.log(`Expand toggled for ${teamName}`)
    }
  }

  const onFilterChange = (e: any) => {
    const value = e.target.value as TeamsFilter

    console.log(`value: ${value}`)

    setActiveTeamsFilter(value)
  }

  const teamIsMine = (scoredPoolTeam: IScoredPoolTeam) =>
    scoredPoolTeam.teamName.startsWith('Chris U')

  const filterTeams = (): IScoredPoolTeams => {
    const allTeamScores = leaderboardData.snapshot.teamScores

    if (activeTeamsFilter === 'me') {
      return allTeamScores.filter(
        (scoredPoolTeam, i) => i === 0 || teamIsMine(scoredPoolTeam)
      )
    } else if (activeTeamsFilter === 'top10') {
      return allTeamScores.filter(
        (scoredPoolTeam, i) => i < 10 || teamIsMine(scoredPoolTeam)
      )
    } else {
      return allTeamScores
    }
  }

  const teamScores = filterTeams()

  // console.log(`Team count: ${teamScores.length}`)

  const allAction: ExpandAction = someExpanded() ? 'close' : 'expand'

  // Hack - the data structure for teams is missing "round" (i.e. "today")
  const getTeamToday = (teamScore: IScoredPoolTeam): string => {
    const idIndex = new Map<string, IScoredPoolPlayer>(
      leaderboardData.snapshot.playerScores.map((playerScore) => [
        playerScore.id,
        playerScore,
      ])
    )

    const numericTeamToday = teamScore.players
      .map((id) => idIndex.get(id))
      .map((scoredPlayer) => {
        if (typeof scoredPlayer === 'undefined') {
          return undefined
        }

        return scoredPlayer.hasOwnProperty('cutperson')
          ? scoredPlayer.cutperson!.round
          : scoredPlayer.round
      })
      .map((stringToday) => getNumericScore(stringToday))
      .reduce((teamToday, today): number | undefined => {
        if (typeof today === 'undefined' || isNaN(today)) {
          return teamToday
        }

        return typeof teamToday === 'undefined' ? today : teamToday + today
      }, undefined)

    return _formatScore(numericTeamToday)
  }

  return (
    <div>
      <LeaderboardHeader time={leaderboardData.snapshot.time} />
      <div className='flex gap-8' onChange={onFilterChange}>
        {filtersConfigs.map((config, i) => (
          <div key={config.value}>
            <input
              type='radio'
              id={`teams-filter-${config.value}`}
              name='teams-filter'
              value={config.value}
              defaultChecked={config.value === activeTeamsFilter}
            />
            <label htmlFor={`teams-filter-${config.value}`} className='pl-2'>
              {config.label}
            </label>
          </div>
        ))}
      </div>
      <div className='flex font-bold mt-2'>
        <div
          className='w-8 text-center'
          onClick={() => toggleExpandAll(allAction)}
        >
          <FontAwesomeIcon
            icon={allAction === 'close' ? faMinusSquare : faPlusSquare}
          />
        </div>
        <div className='w-8 text-center'>Pos</div>
        <div className='w-48 ml-2'>Team</div>
        <div className='w-16 text-center'>Score</div>
        <div className='w-16 text-center'>Today</div>
        <div className='w-16 text-center'>Thru</div>
      </div>
      {teamScores.map((teamScore, i) => {
        return (
          <div key={teamScore.teamName}>
            <div className='flex'>
              <div
                className='w-8 text-center'
                onClick={() => toggleExpandTeam(teamScore.teamName)}
              >
                <FontAwesomeIcon
                  icon={
                    expanded.get(teamScore.teamName)
                      ? faMinusSquare
                      : faPlusSquare
                  }
                />
              </div>
              <div className='w-8 text-center'>{formatTeamPos(teamScore)}</div>
              <div className='w-48 ml-2'>{teamScore.teamName}</div>
              <div className='w-16 text-center'>{formatScore(teamScore)}</div>
              <div className='w-16 text-center'>{getTeamToday(teamScore)}</div>
              <div className='w-16 text-center'>{formatThru(teamScore)}</div>
            </div>
            {expanded.get(teamScore.teamName) ? (
              <ExpandedTeam
                playerScores={leaderboardData.snapshot.playerScores}
                teamScore={teamScore}
              />
            ) : (
              ''
            )}
          </div>
        )
      })}
    </div>
  )
}
